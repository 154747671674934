import React from 'react';
import { CircleProgress } from 'react-gradient-progress';
import { useStaticQuery, graphql } from 'gatsby';
import Helmet from "react-helmet";
import { color } from 'common/styledVars';
import styled from 'styled-components';
import Layout from 'components/Layout';
import { useIntl, Link, FormattedMessage } from "gatsby-plugin-intl";
import {
  BaseTransparentP,
  BaseP,
  HeaderMedium,
  MediumText,
  MediumTextTransparent,
} from 'components/typography';
import IteratingCards from 'components/cards/IteratingCards';
import { Property, WebsiteLinks } from 'components/pages/CaseStudies/styled';
import { DropLogo } from 'components/icons';
import { WidthWrapper, RelativeWrapper } from 'components/wrappers';
import { Arrow, ValueUnit } from 'components/pages/Home/Partners/styled';
import { mediaMin } from 'common/styledMixins';
import { breakpoint, zIndex } from 'common/styledVars';
import {
  ImageBox,
  HeaderSection,
  ResultCard,
  CardsContainer,
  CircleBox,
  TopTextWrapper,
  TextSmallWrapper,
  ImageWrapper,
  GatsbyImage,
  ContentWrapper,
  AreasWrapper,
} from 'components/pages/CaseStudies/styled';
import CopyIcon from 'assets/web-hyperlink.svg';
import BoxIcon from 'assets/box.svg';
import StockIcon from 'assets/stock-2.svg';
import DutiesIcon from 'assets/automated-logistics.svg';

const DropPage = () => {
  const intl = useIntl()
  const data = useStaticQuery(graphql`
    query ImgDropKeyboardQuery {
      file(relativePath: { eq: "drop-keyboard.jpg" }) {
        childImageSharp {
          fluid(quality: 90, maxWidth: 840) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `);

const MainTitle = styled.h1`
    font-size: 28px;
    font-weight: 500;
    margin: 16px 0px 32px;

  @media ${mediaMin(breakpoint.desktop)} {
      font-size: 36px;
      margin: 42px 0 80px;
  }
`;

const OutLink = styled.a`
    color:#9db1ad;
    z-index:9999;
    &:hover {
      color:#00af85
    }
`;
  return (
    <Layout>
      <Helmet>
        {/* <title>Know More About Drop | Shipkoo</title> */}
        <title>{intl.formatMessage({ id: "meta-title-drop" })}</title>
        <link rel="alternate" href={intl.formatMessage({ id: "drop-link" })} hreflang={intl.formatMessage({ id: "language" })} />
        <link rel="alternate" href={intl.formatMessage({ id: "drop-link-alt" })} hreflang={intl.formatMessage({ id: "language-alt" })} />
        <link rel="alternate" href={intl.formatMessage({ id: "drop-link-alt2" })} hreflang={intl.formatMessage({ id: "language-alt2" })} />
        <link rel="alternate" href={intl.formatMessage({ id: "drop-link-alt3" })} hreflang={intl.formatMessage({ id: "language-alt3" })} />
        <meta name="description" content="Get to know why Drop have decided to pick Shipkoo to handle their most crucial logistics operations." />
        <meta property="og:title" content={intl.formatMessage({ id: "meta-title-drop" })} />
        <meta property="og:description" content="Get to know why Drop have decided to pick Shipkoo to handle their most crucial logistics operations." />
        <meta name="twitter:title" content={intl.formatMessage({ id: "meta-title-drop" })} />
        <meta name="twitter:description" content="Get to know why Drop have decided to pick Shipkoo to handle their most crucial logistics operations." />
      </Helmet>
      <RelativeWrapper>
        <WidthWrapper>
          <HeaderSection>
            <BaseP>{intl.formatMessage({ id: "drop-subheader" })}</BaseP>
            <MainTitle>
              {intl.formatMessage({ id: "drop-header" })}
            </MainTitle>
            <BaseTransparentP>
              {intl.formatMessage({ id: "drop-description" })}
            </BaseTransparentP>
          </HeaderSection>
        </WidthWrapper>
        <OutLink href="https://drop.com/" target="_blank">
          <ImageBox>
            <DropLogo alt="Drop Logo" />
          </ImageBox>
        </OutLink>
      </RelativeWrapper>
      <WidthWrapper>
        <CardsContainer>
          <ResultCard>
            <CircleBox color={color.green.aquaSqueeze}>
              <CircleProgress
                percentage={60}
                width={159}
                secondaryColor="transparent"
                primaryColor={['#8bdfbd', '#00b399']}
              />
              <Property>
                60
                <Arrow />
                <ValueUnit>%</ValueUnit>
              </Property>
            </CircleBox>
            <MediumText>{intl.formatMessage({ id: "drop-warehousing" })}</MediumText>
            <BaseTransparentP>{intl.formatMessage({ id: "drop-costs" })}</BaseTransparentP>
          </ResultCard>
          <ResultCard>
            <CircleBox color="#F8F3FF">
              <CircleProgress
                percentage={70}
                width={159}
                secondaryColor="transparent"
                primaryColor={['#d7bfff', '#ae8bff']}
              />
              <Property>
                70
                <Arrow />
                <ValueUnit>%</ValueUnit>
              </Property>
            </CircleBox>
            <MediumText>{intl.formatMessage({ id: "drop-operations" })}</MediumText>
            <BaseTransparentP>{intl.formatMessage({ id: "drop-costs" })}</BaseTransparentP>
          </ResultCard>

          <ResultCard>
            <CircleBox color="#EEFBFF">
              <CircleProgress
                percentage={5}
                width={159}
                secondaryColor="transparent"
                primaryColor={['#59daff', '#00c6ff']}
              />
              <Property>
                5
                <Arrow />
                <ValueUnit>%</ValueUnit>
              </Property>
            </CircleBox>
            <MediumText>{intl.formatMessage({ id: "drop-taxes" })}</MediumText>
            <BaseTransparentP>{intl.formatMessage({ id: "drop-costs" })}</BaseTransparentP>
          </ResultCard>
          <ResultCard>
            <CircleBox color="#FFF9EC">
              <CircleProgress
                percentage={66}
                width={159}
                secondaryColor="transparent"
                primaryColor={['#ffeb78', '#ffd778']}
              />
              <Property>
                <Arrow />4<ValueUnit style={{ right: -31 }}>days</ValueUnit>
              </Property>
            </CircleBox>
            <MediumText>{intl.formatMessage({ id: "drop-operation" })}</MediumText>
            <BaseTransparentP>{intl.formatMessage({ id: "drop-time" })}</BaseTransparentP>
          </ResultCard>
        </CardsContainer>
      </WidthWrapper>

      <TopTextWrapper>
        <HeaderMedium>{intl.formatMessage({ id: "drop-who-title" })}</HeaderMedium>
        <MediumTextTransparent>
          <OutLink href="https://drop.com/" target="_blank">{intl.formatMessage({ id: "drop-who-description-link" })}</OutLink>{intl.formatMessage({ id: "drop-who-description" })}
        </MediumTextTransparent>
      </TopTextWrapper>
      <ImageWrapper>
        <GatsbyImage fluid={data.file.childImageSharp.fluid} alt="Computer Peripheral" />
      </ImageWrapper>

      <WidthWrapper size={640}>
        <ContentWrapper>
          <TextSmallWrapper>
            <BaseP>{intl.formatMessage({ id: "drop-challenge-title" })}</BaseP>
            <BaseTransparentP>
              {intl.formatMessage({ id: "drop-challenge-description" })}
            </BaseTransparentP>
          </TextSmallWrapper>
          <TextSmallWrapper>
            <BaseP>{intl.formatMessage({ id: "drop-solution-title" })}</BaseP>
            <BaseTransparentP>
              {intl.formatMessage({ id: "drop-solution-description" })}
            </BaseTransparentP>
          </TextSmallWrapper>
          <TextSmallWrapper>
            <BaseP>{intl.formatMessage({ id: "drop-improvement-title" })}</BaseP>
            <BaseTransparentP>
              {intl.formatMessage({ id: "drop-improvement-description" })}
            </BaseTransparentP>
          </TextSmallWrapper>
        </ContentWrapper>
      </WidthWrapper>

      <AreasWrapper>
        <IteratingCards
          cards={[
            {
              title: 'Pick & Pack',
              text: 'Labor fulfillment costs are lower in China',
              Icon: BoxIcon,
              titleZH: '提货打包',
              textZH: '中国的劳动力及货物操作成本较低',
              titleKO: '픽앤팩',
              textKO: '중국의 노동 이행 비용은 더 낮다.',
              titleJP: 'ピックアンドパック',
              textJP: '中国では労働履行コストが低い',
            },
            {
              title: 'Warehousing',
              text: 'Drop had less need to maintain a large US warehouse',
              Icon: StockIcon,
              titleZH: '配送中心',
              textZH: 'Drop把部分在中国生产的电子零件货物交到Shipkoo的配送中心',
              titleKO: '입고',
              textKO: 'Drop은 대규모 미국 창고를 유지 관리할 필요가 적음 감소',
              titleJP: '倉庫',
              textJP: 'ドロップは、大規模な米国の倉庫を維持する必要性が少なく',
            },
            {
              title: 'Duties',
              text:
                'Shipkoo specializes in handling parcels that qualify for Section 321 exemptions on duty-free shipping',
              Icon: DutiesIcon,
              Link:
                'Section 321',
              Lasttext:
                'Drop’s product designs can be diverse and complicated, but its logistics does not have to be. Shipkoo handles the company’s shipments in a smarter way and Drop only focuses on growing business instead of worrying about back-end issues.',
              Icon: DutiesIcon,
              titleZH: '营业税',
              textZH: 'Shipkoo处理符合第321条包裹运输的清关审批',
              titleKO: '의무',
              textKO: 'Shipkoo는 소포 취급에 특화되어 있다.',
              titleJP: '職務',
              textJP: 'Shipkooは、免税配送に関する第321条の免除の対象となる小包の取り扱いを専門としています。',
            },
          ]}
        />
      </AreasWrapper>
      <WidthWrapper size={640}>
        <ContentWrapper>
          <BaseTransparentP>{intl.formatMessage({ id: "drop-bottom-description" })}</BaseTransparentP>
        </ContentWrapper>
      </WidthWrapper>
    </Layout>
  );
};

export default DropPage;
